<template>
<div v-if="settings">
  <div class="md:h-136" :style="{ 'background-color': settings.colors.primaryColor }">
    <div class="container flex px-5 mx-auto md:justify-between md:max-w-6xl ">
      <div class="flex items-center justify-center py-16">
        <div class="self-center px-10">
          <h2 class="text-3xl font-extrabold leading-tight text-white md:text-4xl md:tracking-wide md:leading-normal">
            {{ settings ? settings.strings.website.websiteHeaderTitle : ""}}
            <!-- Book your favourite restaurant with lovely menu -->
          </h2>
          <p class="mt-3 text-xl font-normal tracking-widest text-gray-200 md:text-2xl">
            {{ settings ? settings.strings.website.websiteHeaderSubtitle : ""}}
            <!-- Crispy Tofu Salad Greens & Honey Chilli -->
          </p>
          <div class="items-stretch w-full mt-12 md:flex">
            <!-- <input class="block w-full px-4 py-3 mr-2 text-black rounded-md md:py-5 md:w-2/2 focus:border-gray-500" type="text" name="search" placeholder="search product..."> -->
            <button v-on:click="toggleModal()" class="block w-full px-5 py-4 mt-2 font-semibold bg-white rounded-md shadow hover:text-white md:mt-0 hover:bg-gray-100" type="button" :style="{ 'color': settings.colors.primaryColor }">
              Search product
            </button>
          </div>
          <!-- <div class="w-full px-4 py-3 mt-1 bg-white rounded-sm shadow-sm md:w-2/2">
              <div class="flex flex-col" v-if="products">
            
                <div  v-if="products.length <= 0">
                    <p class="mt-5 text-center">No Product</p>
                </div> 
                <div v-else>
                  <div v-for="product in products" :key="product.id">
                    <a :href="$router.resolve({name: 'Product', params: { id: product.id, slug: sanitizeTitle(`${product.name}`) }}).href">
                        <p class="font-semibold">{{ truncate(product.name, 15) }}</p>
                    </a>
                  </div>
                </div>
              </div>
          </div> -->
        </div>
      </div>
      
      <div class="hidden md:mx-auto md:m-10 md:block">
        <img v-if="settings" :src="settings.strings.website.websiteHeaderImage" class="self-center object-contain object-left w-full rounded-xl h-80">
      </div>
    </div>
    
  </div>
  <div class="max-w-6xl px-5 mx-auto ">
    <!-- Categories -->
    <div class="mt-8 md:mt-20">
      <Category/>
    </div>

    <div class="mt-8 md:mt-20">
      <TodaysPicks/>
    </div>

    <div class="mt-8 md:mt-20" id="vendor">
      <nearby-vendor/>
    </div>

  </div>

  <Download/>

  <!-- <div class="my-40 ">
    <VendorBanner/>
  </div> -->
</div>
</template>

<script>
import Category from './Category.vue'
import TodaysPicks from './TodaysPicks.vue'
import Download from '@/components/Downloads.vue'
// import VendorBanner from '@/components/VendorBanner.vue'
import axios from 'axios'
import NearbyVendor from './NearbyVendors.vue'
import { SearchIcon } from '@heroicons/vue/outline'
export default {
  name: 'Home',
  components: {
    Category,
    NearbyVendor,
    TodaysPicks,
    SearchIcon,
    Download,
    // VendorBanner
  },
  data () {
    return {
      settings: null,
      search: null,
      products: null,
      base_url: this.$store.state.baseUrl

    }
  },
  mounted() {
    
    axios.get(this.base_url+'api/app/settings')
      .then((response) => {
        this.settings = response.data
      })
      .catch(error => console.log(error))

  },

  methods: {

    truncate(str, n){
      return (str.length > n) ? str.substr(0, n-1) + '...' : str;
    },

    sanitizeTitle(title) {
      var slug = "";
      // Change to lower case
      var titleLower = title.toLowerCase();
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
      // Letter "d"
      slug = slug.replace(/đ/gi, 'd');
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, '');
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, '-');
      
      return slug;
    },

  },
 
}
</script>

